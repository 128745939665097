.request-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #f5f9fc;
  }
  
  .request-card {
    text-align: center;
    border-radius: 12px;
    padding: 20px;
    width: 520px;
  }
  
  .request-title {
    color: #333;
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 5px;
    text-align: left;
  }
  
  .request-illustration {
    width: 100%;
    height: auto;
    margin: 5px 0;
  }
  
  .request-description {
    color: #666;
    line-height: 1.6;
    margin-bottom: 70px;
    text-align: left;
    font-size: 12px;
    font-weight: bold;
  }
  
  .request-button {
    background-color: #1877f2;
    color: white;
    padding: 8px 10px;
    border: none;
    border-radius: 50px;
    font-size: 12px;
    width: 100%;
    cursor: pointer;
  }
  
  .request-button:hover {
    background-color: #145dbf;
  }
  
  