.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #e9e9e9;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    box-shadow: none;
    border: none;
  }
  
  .loading-dialog {
    width: 100%;
    padding: 20px;
    text-align: center;
    
  }

  .meta-logo {
    width: 120px;
    margin: auto;
    margin-bottom: 20px;
  }
  
  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(0, 0, 0, 0.2);
    border-top: 4px solid #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 0 auto 10px;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  